import React from 'react';
import PropTypes from 'prop-types';

export const CommaArray = props => {
    if (!props.data || !Array.isArray(props.data)) {
        return null;
    }

    return (
        <div className="color_black_50">
            {props.data.map((item, index) => (
                <span key={index}>
                    {props.value ? item[props.value] : item}
                    {props.data.length > index + 1 ? <span>, </span> : null}
                </span>
            ))}
        </div>
    );
};

CommaArray.propTypes = {
    data: PropTypes.array.isRequired,
    value: PropTypes.string
};

/* eslint-disable react/prop-types */
import React from 'react';
import { FormField, Translate, formFieldType } from '../../../../components';
import { Button, Modal } from 'antd';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { jobActions } from '../../../../api';
import { classificatorTranslatedArray, formatNumber, i18, i18cl, required, translate } from '../../../../utilities';
import OfferForm from './OfferForm';

export default function OfferModal({
    toggleModal,
    jobId,
    showModal,
    serviceId,
    availableVats = [],
    availableMaterialVats = [],
    vatName: initialVatName,
    materialsVatName: initialMaterialsVatName,
    vatType: initialVatType,
    materialsVatType: initialMaterialsVatType
}) {
    const offers = useSelector(s => s.jobReducers.offers);

    const dispatch = useDispatch();

    const onSubmit = e => {
        dispatch(
            jobActions.getJobGeneratedOffers(jobId, {
                adminFee: e.adminFee,
                partnerReward: e.partnerReward,
                materialPrice: e.materialPrice,
                quantity: e.quantity,
                serviceVat: {
                    name: e.vatName,
                    type: e.vatType,
                    percentage: availableVats.find(vat => vat.name === e.vatName)?.percentage
                },
                materialsVat: {
                    name: e.materialsVatName,
                    type: e.materialsVatType,
                    percentage: availableMaterialVats.find(vat => vat.name === e.materialsVatName)?.percentage
                }
            })
        );
    };

    const onApprove = async e => {
        try {
            await dispatch(
                jobActions.postJobGeneratedOffers(jobId, {
                    offers: Object.values(e)
                })
            );
            toggleModal();
            dispatch(jobActions.clearJobGeneratedOffers());
        } catch (error) {}
    };

    const onCancel = () => {
        if (offers.length > 0) {
            const confirmed = window.confirm(translate(i18.Job.Labels.OffersConfirmationQuestion));
            if (confirmed) {
                toggleModal();
                dispatch(jobActions.clearJobGeneratedOffers());
            }
            return;
        }
        toggleModal();
        dispatch(jobActions.clearJobGeneratedOffers());
    };

    const vatTypes = classificatorTranslatedArray(i18cl.JOB_VAT_TYPES.replace(/\.$/, ''));

    return (
        <Modal
            title={<Translate value={i18.Job.Labels.OffersModalTitle} />}
            visible={showModal}
            onCancel={onCancel}
            footer={null}
            maskClosable={false}
            width={'90%'}
        >
            <>
                <Form
                    key={showModal}
                    destroyOnUnregister
                    onSubmit={onSubmit}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <Translate value={i18.Job.Labels.OffersTitle} />
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-3">
                                    <FormField
                                        title={i18.Job.Labels.OffersAdminFee}
                                        borderTitle
                                        name={'adminFee'}
                                        validate={required}
                                        component={formFieldType.number}
                                    />
                                </div>
                                <div className="col-6 col-md-3">
                                    <FormField
                                        title={i18.Job.Labels.OffersPartnerReward}
                                        borderTitle
                                        name={'partnerReward'}
                                        validate={required}
                                        component={formFieldType.number}
                                    />
                                </div>
                                <div className="col-6 col-md-3">
                                    <FormField
                                        title={i18.Job.Labels.OffersMaterialPrice}
                                        borderTitle
                                        name={'materialPrice'}
                                        component={formFieldType.number}
                                    />
                                </div>
                                <div className="col-6 col-md-3">
                                    <FormField
                                        title={i18.Job.Labels.OffersQuantity}
                                        borderTitle
                                        name={'quantity'}
                                        validate={required}
                                        component={formFieldType.number}
                                        initialValue="3"
                                        formatNumber={formatNumber().number}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-3">
                                    <FormField
                                        title={i18.Job.Labels.ServiceVatName}
                                        borderTitle
                                        component={formFieldType.select}
                                        name={'vatName'}
                                        defaultValue={initialVatName}
                                        options={availableVats.map(vat => ({
                                            value: vat.name,
                                            label: vat.name
                                        }))}
                                    />
                                </div>
                                <div className="col-6 col-md-3">
                                    <FormField
                                        title={i18.Job.Labels.ServiceVatType}
                                        borderTitle
                                        name={'vatType'}
                                        defaultValue={initialVatType}
                                        component={formFieldType.select}
                                        options={vatTypes}
                                    />
                                </div>
                                <div className="col-6 col-md-3">
                                    <FormField
                                        title={i18.Job.Labels.MaterialVatName}
                                        borderTitle
                                        component={formFieldType.select}
                                        name={'materialsVatName'}
                                        defaultValue={initialMaterialsVatName}
                                        options={availableMaterialVats.map(vat => ({
                                            value: vat.name,
                                            label: vat.name
                                        }))}
                                    />
                                </div>
                                <div className="col-6 col-md-3">
                                    <FormField
                                        title={i18.Job.Labels.MaterialVatType}
                                        borderTitle
                                        name={'materialsVatType'}
                                        component={formFieldType.select}
                                        defaultValue={initialMaterialsVatType}
                                        options={vatTypes}
                                    />
                                </div>
                            </div>
                            <Button htmlType="submit" className={'rounded job-confirm-button'}>
                                <Translate value={i18.Job.Labels.OffersGenerate} />
                            </Button>
                        </form>
                    )}
                />
                <div className="d-flex flex-column mt-1">
                    <div>
                        <Translate value={i18.Job.Labels.OffersGenerated} />
                    </div>
                    <Form
                        onSubmit={onApprove}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <OfferForm offers={offers} jobId={jobId} serviceId={serviceId} />
                                {offers?.length ? (
                                    <div className="d-flex flex-row justify-content-end">
                                        <Button htmlType="submit" className={'m-2 rounded job-confirm-button'}>
                                            <Translate value={i18.Job.Labels.OffersSubmit} />
                                        </Button>
                                        <Button onClick={onCancel} className={'m-2 rounded job-edit-button-cancel'}>
                                            <Translate value={i18.Job.Labels.OffersCancel} />
                                        </Button>
                                    </div>
                                ) : null}
                            </form>
                        )}
                    />
                </div>
            </>
        </Modal>
    );
}

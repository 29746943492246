import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'antd';
import { Classificator, FormField, formFieldType, Translate } from '../../../components';
import { fieldNames } from './fieldNames';
import { jobCreateFormActions } from '../../../api';
import { isJobAWork, isJobFactual } from '..';
import { getCurrentDate, i18, i18cl } from '../../../utilities';

class SettingsFormSection extends React.Component {
    constructor(props) {
        super(props);

        this.onJobTypeSwitched = this.onJobTypeSwitched.bind(this);
    }

    componentDidUpdate() {
        const { jobTypes } = this.props;

        if (jobTypes && jobTypes.length === 2) {
            const jobType = this.props.isJobTypeEmergency ? jobTypes[0] : jobTypes[1];

            if (this.props.formValues.jobType === undefined) {
                this.props.updateKeyValue(fieldNames.jobType, jobType);
            }
        }
    }

    onJobTypeSwitched(isChecked) {
        const { jobTypes } = this.props;
        const jobType = isChecked ? jobTypes[0] : jobTypes[1];

        this.props.dispatch(jobCreateFormActions.setIsJobTypeEmergency(isChecked));
        this.props.updateKeyValue(fieldNames.jobType, jobType);
    }

    render() {
        return (
            <>
                <Translate className="fs-16 fw_600" value={i18.JobCreateForm.Titles.Settings} />
                <div className="container-default mt-4">
                    <div className="row mb-3">
                        <div className="col-6">
                            {!isJobFactual(this.props.formValues.biddingType) && (
                                <FormField
                                    component={formFieldType.number}
                                    placeholder={i18.JobCreateForm.Labels.ClientPrice}
                                    name={fieldNames.clientPrice}
                                />
                            )}
                        </div>
                        {isJobAWork(this.props.selectedJobStatus.statusCode) &&
                            !isJobFactual(this.props.formValues.biddingType) && (
                                <div className="col-6">
                                    <FormField
                                        placeholder={i18.JobCreateForm.Labels.PartnerReward}
                                        name={fieldNames.partnerReward}
                                        component={formFieldType.number}
                                    />
                                </div>
                            )}
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            {!isJobFactual(this.props.formValues.biddingType) && (
                                <FormField
                                    placeholder={i18.JobCreateForm.Labels.AdministrationFee}
                                    name={fieldNames.administrationFee}
                                    component={formFieldType.number}
                                />
                            )}
                        </div>
                        <div className="col-6 d-flex">
                            <Translate className="ml-3 mr-3 text-secondary" value={i18.JobCreateForm.Labels.JobType} />
                            <Switch
                                checked={this.props.isJobTypeEmergency}
                                className="flex-grow-1 float-right"
                                checkedChildren={<Classificator value={i18cl.TYPES_WORK + this.props.jobTypes[0]} />}
                                unCheckedChildren={<Classificator value={i18cl.TYPES_WORK + this.props.jobTypes[1]} />}
                                onChange={this.onJobTypeSwitched}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <FormField
                                name={fieldNames.biddingDateRange}
                                component={formFieldType.dateRangePicker}
                                placeholderFrom={i18.JobCreateForm.Labels.BiddingStartDate}
                                placeholderTo={i18.JobCreateForm.Labels.BiddingEndDate}
                                time
                                minDate={{
                                    date: getCurrentDate(0)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

SettingsFormSection.propTypes = {
    formValues: PropTypes.object.isRequired,
    isJobTypeEmergency: PropTypes.bool,
    selectedJobStatus: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    jobTypes: PropTypes.array,
    updateKeyValue: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const { isJobTypeEmergency, selectedJobStatus } = state.jobCreateFormReducers;

    return {
        isJobTypeEmergency,
        selectedJobStatus
    };
}

const connectedSettingsFormSection = connect(mapStateToProps)(SettingsFormSection);
export { connectedSettingsFormSection as SettingsFormSection };

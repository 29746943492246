import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { JobBiddingsGrid, JobInformation, JobInvoices } from './';
import { Button, Popconfirm, Tabs } from 'antd';
import { Form } from 'react-final-form';
import { serialize } from 'object-to-formdata';
import { classificator, getPhoneNumberParts, i18, i18cl, routerPaths, translate, toDaysString } from '../../utilities';
import { ButtonWrapper, ContentHeader, Translate } from '../../components';
import {
    jobActions,
    localJobActions,
    jobCreateActions,
    JobsStatusConstants,
    odataActions,
    BiddingTypesConstants
} from '../../api';
import { JobClient } from './jobClient';
import { fieldNames } from './fieldNames';
import { isJobFactual, isJobStandard, isJobFixed } from '../jobsCreate';
import { JobCompleteForm } from './jobCompleteForm';
import { PartnerReassignForm } from './reassignPartner/partnerReassignForm';
import { OpenUrlButton, SetClientCalledButton } from './components';
import { FILE_EDIT_STATUSES } from './constants';

const { TabPane } = Tabs;

const actionTypes = {
    complete: 'complete'
};

class JobContainer extends React.Component {
    constructor(props) {
        super(props);
        this.jobForm = null;

        this.toogleReassignPartnerModal = this.toogleReassignPartnerModal.bind(this);
        this.setJobEditable = this.setJobEditable.bind(this);
        this.submitUpdatedJobInformation = this.submitUpdatedJobInformation.bind(this);
        this.cancelUpdatingJobInformation = this.cancelUpdatingJobInformation.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.reloadJobData = this.reloadJobData.bind(this);
        this.reloadBiddingListData = this.reloadBiddingListData.bind(this);
        this.onApproveWinner = this.onApproveWinner.bind(this);
        this.openCompleteForm = this.openCompleteForm.bind(this);
        this.closeCompleteForm = this.closeCompleteForm.bind(this);
        this.confirmJob = this.confirmJob.bind(this);
        this.onAssignNewPartner = this.onAssignNewPartner.bind(this);
        this.onAssignNewPartnerStandard = this.onAssignNewPartnerStandard.bind(this);
        this.startBiddingJob = this.startBiddingJob.bind(this);
        this.sendJobToClient = this.sendJobToClient.bind(this);
        this.onViewJobScopeFile = this.onViewJobScopeFile.bind(this);

        this.state = {
            actionType: null,
            showReassignPartnersGrid: false
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(jobCreateActions.getJobCreationInputs());
        dispatch(jobActions.getJob(this.props.match.params.jobId));
        dispatch(jobActions.getJobValidationStatuses());
    }

    componentWillUnmount() {
        this.props.dispatch(localJobActions.setJobEditable(false));
        this.props.dispatch(localJobActions.setJobFilesEditable(false));
    }

    isRecalculationNeeded(values, form) {
        const hasMaterialExpensesChanged = form.getFieldState(fieldNames.materialExpenses)?.dirty;

        if (isJobFactual(values.biddingType) && !hasMaterialExpensesChanged) {
            return false;
        }

        const hasClientPriceChanged = form.getFieldState(fieldNames.clientPrice).dirty;
        const hasAdministrationFeeChanged = form.getFieldState(fieldNames.administrationFee).dirty;

        if (hasClientPriceChanged || hasAdministrationFeeChanged || hasMaterialExpensesChanged) {
            return true;
        }

        if (values.jobStatus !== JobsStatusConstants.NEW) {
            const hasPartnerRewarChanged = form.getFieldState(fieldNames.partnerReward).dirty;
            return hasPartnerRewarChanged;
        }

        return false;
    }

    mutateSubmit(values, form) {
        const putValues = {
            description: values.description,
            additionalObjectInformation: values.additionalObjectInformation,
            deadline: values.jobDeadline,
            isRecalculationNeeded: this.isRecalculationNeeded(values, form),
            fullPrice: values.fullPrice,
            platformPrice: values.administrationFee,
            partnerReward: values.partnerReward,
            administratorComment: values.administratorComment,
            materialExpenses: values.materialExpenses,
            workVatType: values.vatType,
            workVatName: values.vatName,
            attachments: values.attachments,
            plannedStartDate: values.plannedStartDate,
            materialsVatName: values.materialsVatName,
            materialsVatType: values.materialsVatType,
            materialsVatPercentage:
                this.props.job.availableMaterialVats.find(v => v.name === values.materialsVatName)?.percentage ?? '',
            workVatPercentage: this.props.job.availableVats.find(v => v.name === values.vatName)?.percentage ?? '',
            preliminaryMaterialsPrice: values.preliminaries?.materialsPrice,
            preliminaryPartnerReward: values.preliminaries?.partnerReward
        };

        return serialize(putValues);
    }

    onSubmit(values, form) {
        this.props
            .dispatch(jobActions.updateJob(this.props.job.jobId, this.mutateSubmit(values, form)))
            .then(this.reloadJobData);
    }

    reloadJobData() {
        this.props.dispatch(localJobActions.setJobEditable(false));
        this.props.dispatch(localJobActions.setJobFilesEditable(false));
        return new Promise(resolve => {
            this.props.dispatch(jobActions.getJob(this.props.match.params.jobId)).then(resolve);
        });
    }

    reloadBiddingListData(jobId, odataQuery) {
        this.props.dispatch(odataActions.getJobBiddingsGrid(jobId, odataQuery));
    }

    setJobEditable(isEditable) {
        this.props.dispatch(localJobActions.setJobEditable(isEditable));
    }

    setJobFilesEditable(isEditable) {
        this.props.dispatch(localJobActions.setJobFilesEditable(isEditable));
    }

    toogleReassignPartnerModal() {
        this.setState(prevState => ({
            showReassignPartnersGrid: !prevState.showReassignPartnersGrid
        }));
    }

    submitUpdatedJobInformation() {
        this.jobForm.submit();
    }

    cancelUpdatingJobInformation() {
        this.setJobEditable(false);
        this.setJobFilesEditable(false);
        this.jobForm.restart();
    }

    cancelJob() {
        this.props.dispatch(jobActions.cancelJob(this.props.match.params.jobId)).then(() => {
            this.reloadJobData();
        });
    }

    startBiddingJob() {
        this.props.dispatch(jobActions.startBiddingJob(this.props.match.params.jobId)).then(() => {
            this.reloadJobData();
        });
    }

    sendJobToClient() {
        this.props.dispatch(jobActions.sendJobToClient(this.props.match.params.jobId)).then(() => {
            this.reloadJobData();
        });
    }

    confirmJob(response) {
        this.props.dispatch(jobActions.completeJob(this.props.match.params.jobId, response)).then(() => {
            this.reloadJobData();
        });
    }

    openCompleteForm() {
        this.setState({
            actionType: actionTypes.complete
        });
    }

    closeCompleteForm() {
        this.setState({
            actionType: null
        });
    }

    onApproveWinner(partnerId) {
        const payload = {
            partnerId: partnerId
        };

        return new Promise(resolve => {
            this.props.dispatch(jobActions.approveJob(this.props.match.params.jobId, payload)).then(() => {
                this.reloadJobData().then(resolve);
            });
        });
    }

    onViewJobScopeFile(partnerId) {
        return new Promise(resolve => {
            this.props.dispatch(jobActions.getJobScopeFileUrl(this.props.match.params.jobId, partnerId)).then(resolve);
        });
    }

    onAssignNewPartner(partnerId) {
        this.props
            .dispatch(jobActions.assignNewPartner(this.props.match.params.jobId, partnerId))
            .then(() => {
                this.reloadJobData();
            })
            .finally(() => {
                this.toogleReassignPartnerModal();
            });
    }

    onAssignNewPartnerStandard(partnerId) {
        this.props
            .dispatch(jobActions.assignNewPartnerStandard(this.props.match.params.jobId, partnerId))
            .then(() => {
                this.reloadJobData();
            })
            .finally(() => {
                this.toogleReassignPartnerModal();
            });
    }

    assignForm(form) {
        this.jobForm = form;
    }

    renderMainButtons() {
        const {
            jobValidationStatuses,
            job: { jobStatus, allowIntegratorActions, clientSegment, biddingType, jobId, adminCallInfo }
        } = this.props;

        const canSetClientCalled = !!jobId;
        const canReassignPartner =
            (isJobStandard(biddingType) &&
                jobValidationStatuses.canReassignStandardJobPartnerStatuses.includes(jobStatus)) ||
            (isJobFixed(biddingType) && jobValidationStatuses.canReassignFixedJobPartnerStatuses.includes(jobStatus));
        const canEdit = !jobValidationStatuses.canNotEditStatuses.includes(jobStatus);
        const canEditFiles = FILE_EDIT_STATUSES.includes(jobStatus);
        const canBidding =
            jobValidationStatuses.canStartBidding.includes(jobStatus) &&
            (isJobStandard(biddingType) || isJobFixed(biddingType));
        const canCancel = jobValidationStatuses.canCancelStatuses.includes(jobStatus) && allowIntegratorActions;
        const canComplete = jobValidationStatuses.canCompleteStatuses.includes(jobStatus) && allowIntegratorActions;
        const invoiceGroupingEnabled = this.props.featureToggles.invoiceGroupingEnabled?.indexOf(clientSegment) > -1;

        const mainButtons = (
            <Button.Group className="row justify-content-end ml-4">
                {canSetClientCalled && <SetClientCalledButton jobId={jobId} adminCallInfo={adminCallInfo} />}
                {canReassignPartner && (
                    <Button
                        className="rounded border-0 job-confirm-button mr-3 mb-3"
                        onClick={this.toogleReassignPartnerModal}
                        disabled={this.props.send}
                    >
                        <Translate value={i18.Job.Buttons.AssignNewPartner} />
                    </Button>
                )}
                {this.props.job.allowToManageData && (
                    <>
                        {canBidding && (
                            <>
                                <Popconfirm
                                    onConfirm={this.sendJobToClient}
                                    title={<Translate value={i18.Job.Labels.SendConfirmationTitle} />}
                                    okText={<Translate value={i18.Job.Labels.ConfirmationOkText} />}
                                    cancelText={<Translate value={i18.Job.Labels.ConfirmationCancelText} />}
                                >
                                    <ButtonWrapper
                                        className="mr-3 mb-3 rounded border-0 job-edit-button"
                                        disabled={this.props.send}
                                    >
                                        <Translate value={i18.Job.Buttons.SendToClient} />
                                    </ButtonWrapper>
                                </Popconfirm>
                                <Popconfirm
                                    onConfirm={this.startBiddingJob}
                                    title={<Translate value={i18.Job.Labels.SendConfirmationTitle} />}
                                    okText={<Translate value={i18.Job.Labels.ConfirmationOkText} />}
                                    cancelText={<Translate value={i18.Job.Labels.ConfirmationCancelText} />}
                                >
                                    <ButtonWrapper
                                        className="mr-3 mb-3 rounded border-0 job-edit-button"
                                        disabled={this.props.send}
                                    >
                                        <Translate value={i18.Job.Buttons.StartBidding} />
                                    </ButtonWrapper>
                                </Popconfirm>
                            </>
                        )}
                        {canEdit && (
                            <Button
                                className="mr-3 mb-3 rounded border-0 job-edit-button"
                                onClick={() => this.setJobEditable(true)}
                                disabled={this.props.send}
                            >
                                <Translate value={i18.Job.Buttons.Edit} />
                            </Button>
                        )}
                        {!canEdit && canEditFiles && (
                            <Button
                                className="mr-3 mb-3 rounded border-0 job-edit-button"
                                onClick={() => this.setJobFilesEditable(true)}
                                disabled={this.props.send}
                            >
                                <Translate value={i18.Job.Buttons.EditFiles} />
                            </Button>
                        )}
                        {canCancel && (
                            <Popconfirm
                                overlayInnerStyle={{ minWidth: 200 }}
                                placement="bottomLeft"
                                title={<Translate value={i18.Job.Confirms.JobCancel} />}
                                onConfirm={() => this.cancelJob()}
                                okText={<Translate value={i18.Job.Buttons.ConfirmJobCancel} />}
                                cancelText={<Translate value={i18.Job.Buttons.ConfirmCancel} />}
                            >
                                <Button
                                    className="rounded border-0 job-cancel-button mr-3 mb-3"
                                    disabled={this.props.send}
                                >
                                    <Translate value={i18.Job.Buttons.CancelJob} />
                                </Button>
                            </Popconfirm>
                        )}
                        {canComplete && !invoiceGroupingEnabled && (
                            <Popconfirm
                                overlayInnerStyle={{ minWidth: 200 }}
                                placement="bottomLeft"
                                title={<Translate value={i18.Job.Confirms.JobConfirm} />}
                                onConfirm={() => this.confirmJob({})}
                                okText={<Translate value={i18.Job.Buttons.ConfirmJobConfirm} />}
                                cancelText={<Translate value={i18.Job.Buttons.CancelJobConfirm} />}
                            >
                                <Button
                                    className="rounded border-0 job-confirm-button mr-3 mb-3"
                                    disabled={this.props.send}
                                >
                                    <Translate value={i18.Job.Buttons.ConfirmJob} />
                                </Button>
                            </Popconfirm>
                        )}
                        {canComplete && invoiceGroupingEnabled && (
                            <>
                                <Button
                                    className="rounded border-0 job-confirm-button ml-3 mb-3"
                                    disabled={false}
                                    onClick={this.openCompleteForm}
                                >
                                    <Translate value={i18.Job.Buttons.ConfirmJob} />
                                </Button>
                                <JobCompleteForm
                                    actionType={this.state.actionType}
                                    onCancel={this.closeCompleteForm}
                                    submit={this.confirmJob}
                                    initialValues={{}}
                                />
                            </>
                        )}
                    </>
                )}
            </Button.Group>
        );

        return mainButtons;
    }

    renderEditingButtons() {
        const onEditingButtons = (
            <Button.Group className="row justify-content-end ml-4">
                <Button
                    className={'mr-2 rounded'}
                    onClick={this.cancelUpdatingJobInformation}
                    disabled={this.props.send}
                >
                    <Translate value={i18.Job.Buttons.Cancel} />
                </Button>
                <ButtonWrapper
                    type="primary"
                    htmlType="submit"
                    disabled={this.props.send}
                    className="rounded"
                    onClick={this.submitUpdatedJobInformation}
                >
                    <Translate value={i18.Job.Buttons.Save} />
                </ButtonWrapper>
            </Button.Group>
        );

        return onEditingButtons;
    }

    render() {
        const {
            jobValidationStatuses,
            job: { jobStatus, serviceId, biddingType }
        } = this.props;
        const { job } = this.props;

        const canReassignPartner =
            (isJobStandard(biddingType) &&
                jobValidationStatuses.canReassignStandardJobPartnerStatuses.includes(jobStatus)) ||
            (isJobFixed(biddingType) && jobValidationStatuses.canReassignFixedJobPartnerStatuses.includes(jobStatus));

        const contactInitialPhoneNumber = getPhoneNumberParts(job[fieldNames.contactPhoneNumber]);
        const managerInitialPhoneNumber = getPhoneNumberParts(job[fieldNames.managerPhone]);

        const reassignPartner = isJobFixed(biddingType)
            ? this.onAssignNewPartner
            : isJobStandard(biddingType)
            ? this.onAssignNewPartnerStandard
            : () => undefined;

        return (
            <>
                <ContentHeader
                    back={
                        this.props.history.location.state?.jobPrevRoute ??
                        this.props.history.location.state?.prevRoute ??
                        routerPaths.jobs.index
                    }
                    backState={this.props.history.location.state?.jobPrevState ?? this.props.history.location.state}
                    title={
                        <div className="d-flex my-auto">
                            <p>
                                {classificator(i18cl.SERVICES_CATALOG + this.props.job.serviceCode)}{' '}
                                <b>{this.props.job.jobCode}</b>
                            </p>
                            {this.props.job.isCreatedFromLead && (
                                <div className="ml-2">
                                    <div
                                        style={{
                                            backgroundColor: '#E8F5E9',
                                            color: '#34A853',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: 8,
                                            padding: 4
                                        }}
                                    >
                                        <Translate value={i18.Job.Labels.LeadOffer} className="text-nowrap fs-14" />
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    extra={
                        this.props.isJobEditable || this.props.isJobFilesEditable
                            ? this.renderEditingButtons()
                            : this.renderMainButtons()
                    }
                />
                <div className="content_block">
                    <Form
                        mutators={{
                            updateKeyValue: (args, state, utils) => {
                                utils.changeValue(state, args[0], () => args[1]);
                            }
                        }}
                        initialValues={{
                            [fieldNames.contactNumberPrefix]: contactInitialPhoneNumber.prefix,
                            [fieldNames.contactNationalNumber]: contactInitialPhoneNumber.number,
                            [fieldNames.managerPhoneNumber]: managerInitialPhoneNumber.number,
                            [fieldNames.managerPhoneNumberPrefix]: managerInitialPhoneNumber.prefix,
                            [fieldNames.partnerLink]:
                                job.partnerId !== null
                                    ? window.location.host +
                                      routerPaths.partners.index +
                                      '/' +
                                      job.partnerId +
                                      '/profile'
                                    : null,
                            [fieldNames.mainClientName]: job.mainClientName,
                            [fieldNames.mainClientId]: job.mainClientId,
                            [fieldNames.plannedStartDate]: job.plannedStartDate,
                            [fieldNames.biddingDateRange]: {
                                from: job.jobAdStartDateTime,
                                to: job.jobAdDeadlineDateTime
                            },
                            [fieldNames.jobDateRange]: {
                                from: job.jobStartDateTime,
                                to: job.jobEndDateTime
                            },
                            [fieldNames.callDate]: job.callInfo?.callDate,
                            [fieldNames.callComment]: job.callInfo?.callComment,
                            [fieldNames.calledBy]: job.callInfo?.calledBy,
                            ...job,
                            ...(job.factualDuration
                                ? {
                                      factualHours: `${job.factualDuration.hours} ${translate(
                                          i18.Job.Labels.FactualHours
                                      )} ${job.factualDuration.minutes} ${translate(i18.Job.Labels.FactualMinutes)}`
                                  }
                                : {}),
                            ...(job.duration > 0 ? { duration: toDaysString(job.duration) } : {})
                        }}
                        onSubmit={this.onSubmit}
                        render={({ handleSubmit, values, form }) => {
                            this.assignForm(form);
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab={translate(i18.Job.Titles.MainJobInfo)} key="1">
                                            <JobInformation form={form} values={values} />
                                        </TabPane>
                                        <TabPane tab={translate(i18.Job.Titles.ClientInfo)} key="2">
                                            <JobClient />
                                        </TabPane>
                                        <TabPane tab={translate(i18.Job.Titles.BiddingAssignedPartners)} key="3">
                                            <JobBiddingsGrid
                                                onApproveWinner={this.onApproveWinner}
                                                reloadBiddingListData={this.reloadBiddingListData}
                                                onViewJobScopeFile={this.onViewJobScopeFile}
                                                isEditable={
                                                    [JobsStatusConstants.PREORDER, JobsStatusConstants.NEW].includes(
                                                        job.jobStatus
                                                    ) && job.biddingType === BiddingTypesConstants.STANDARD
                                                }
                                                serviceId={serviceId}
                                            />
                                        </TabPane>
                                        <TabPane tab={translate(i18.Job.Titles.Invoices)} key="4">
                                            <JobInvoices
                                                onApproveWinner={this.onApproveWinner}
                                                reloadBiddingListData={this.reloadBiddingListData}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </form>
                            );
                        }}
                    />
                    {canReassignPartner && serviceId && (
                        <PartnerReassignForm
                            serviceId={serviceId}
                            showPartnersGrid={this.state.showReassignPartnersGrid}
                            onFormToogle={this.toogleReassignPartnerModal}
                            onPartnersSelected={reassignPartner}
                        />
                    )}
                </div>
            </>
        );
    }
}

JobContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isJobEditable: PropTypes.bool.isRequired,
    isJobFilesEditable: PropTypes.bool.isRequired,
    job: PropTypes.object,
    jobValidationStatuses: PropTypes.object,
    send: PropTypes.bool,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    featureToggles: PropTypes.object,
    vats: PropTypes.array
};

function mapStateToProps(state) {
    const { job, jobValidationStatuses, send } = state.jobReducers;

    const { isJobEditable, isJobFilesEditable } = state.localJobReducers;

    const { featureToggles } = state.featureTogglesReducers;
    const countryVats = state.countryVatReducers.countryVats;
    return {
        isJobEditable,
        isJobFilesEditable,
        job: job?.value || {},
        jobValidationStatuses,
        send,
        featureToggles,
        vats: countryVats?.vats ?? []
    };
}

const connectedJobContainer = withRouter(connect(mapStateToProps)(JobContainer));
export { connectedJobContainer as JobContainer };

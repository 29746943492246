export const apiPaths = {
    administrationFeeMargins: 'administration-fee-margins/',
    internalClients: {
        internalClient: 'internalClient'
    },
    countries: 'countries',
    countrySettings: 'countrySettings',
    countriesLangauges: 'countries/languages',
    countriesVat: 'countryVats',
    allLanguages: 'languages/all',
    featureToggles: 'feature-toggles',
    odata: {
        internalClientsGrid: 'InternalClientsGridQuery',
        jobsGrid: 'JobGridQuery',
        partnersGrid: 'PartnerGridQuery',
        PartnerGridForServiceAndClientSegment: 'PartnerGridForServiceAndClientSegmentQuery',
        PartnerGridForService: 'PartnerGridForServiceQuery',
        PartnerGridClientSegmentQuery: 'PartnerGridClientSegmentQuery',
        jobBiddingsGrid: 'JobBiddingsGridQuery'
    },
    public: {
        bankName: 'public/bankName',
        languages: 'public/languages/',
        localization: 'public/localization/admin',
        serviceAreas: 'public/serviceAreas',
        content: 'public/content',
        configuration: 'public/configuration'
    },
    partnerManagement: 'partner/',
    partnerProfile: 'partnerProfile',
    partnerRead: 'partner/',
    partnerServiceManagement: {
        index: 'partner/'
    },
    partnerProfileServiceItem: {
        index: 'partners/',
        serviceItem: '/service-item',
        serviceItems: '/service-items'
    },
    partnerServiceRead: {
        index: 'partner/'
    },
    serviceSettings: 'serviceSettings',
    serviceGroups: {
        serviceGroups: 'service-groups'
    },
    serviceItems: 'service-items',
    serviceItemsV2: 'service-itemsV2',
    services: 'services',
    translation: 'translation/',
    user: {
        login: 'user/login/',
        changePassword: 'user/changePassword',
        resetPassword: 'user/resetPassword',
        createPassword: 'user/createPassword/'
    },
    jobs: {
        index: 'jobs',
        v2: 'admin/v2/jobs',
        creationInputs: 'jobs/manualJobCreationInputs'
    },
    monthlyRewards: 'monthlyRewards/'
};

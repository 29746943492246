import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { formatNumber, i18, required, requiredArray } from '../../../../../utilities';
import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../../../components';

export const PartnerServiceForm = (props) => {
    props.values.radius = props.values.areas[0]?.radius;
    return (
        <Form
            onSubmit={props.submit}
            keepDirtyOnReinitialize
            initialValues={{
                ...props.values,
                areas: props.values.areas.map(item => item.serviceAreaId)
            }}
            render={({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <div className='mb-3'>
                        <FormField
                            inline
                            component={formFieldType.multiSelect}
                            disabled={props.submitting}
                            name='areas'
                            title={i18.PartnerServiceForm.Labels.Areas}
                            options={props.areas}
                            optionsLabel='name'
                            optionsValue='serviceAreaId'
                            validate={requiredArray}
                        />
                        <FormField
                            inline
                            component={formFieldType.number}
                            formatNumber={formatNumber().numberPositive}
                            disabled={props.submitting}
                            name='radius'
                            title={i18.PartnerServiceForm.Labels.AreasRadius}
                            validate={required}
                        />
                        <FormField
                            inline
                            component={formFieldType.number}
                            formatNumber={formatNumber().numberPositive}
                            disabled={props.submitting}
                            name='hourlyRate'
                            title={`${i18.PartnerServiceForm.Labels.HourlyRate}${props.currency}`}
                        />
                    </div>
                    <div className='text-right'>
                        <ButtonGroup>
                            <ButtonWrapper
                                type='text'
                                disabled={props.submitting}
                                onClick={() => props.cancel()}
                            >
                                <Translate value={i18.PartnerServiceForm.Buttons.Cancel} />
                            </ButtonWrapper>
                            <ButtonWrapper
                                type='primary'
                                htmlType='submit'
                                loading={props.submitting}
                            >
                                <Translate value={i18.PartnerServiceForm.Buttons.Submit} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    </div>
                </form>
            )}
        />
    );
};

PartnerServiceForm.propTypes = {
    cancel: PropTypes.func.isRequired,
    areas: PropTypes.array.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    values: PropTypes.object,
    currency: PropTypes.string
};

import { publicConstants } from './constants';

export const publicReducers = function (
    state = {
        publicLanguages: [],
        sendPublicLanguages: true,
        sendPublicLocalization: true,
        sendPublicServiceAreasGET: true,
        sendPublicContentGET: true,
        publicServiceAreas: [],
        publicContent: {},
        publicConfigurations: {}
    },
    action
) {
    switch (action.type) {
        case publicConstants.GET_PUBLIC_BANK_NAME_REQUEST:
            return {
                ...state,
                send: true,
                sendPublicBankNameGET: true
            };
        case publicConstants.GET_PUBLIC_BANK_NAME_SUCCESS:
            return {
                ...state,
                send: false,
                sendPublicBankNameGET: false,
                bankName: action.payload
            };
        case publicConstants.GET_PUBLIC_BANK_NAME_ERROR:
            return {
                ...state,
                send: false,
                sendPublicBankNameGET: false,
                errors: action.error
            };
        case publicConstants.GET_PUBLIC_LANGUAGES_REQUEST:
            return {
                ...state,
                send: true
            };
        case publicConstants.GET_PUBLIC_LANGUAGES_SUCCESS:
            return {
                ...state,
                publicLanguages: action.payload,
                send: false,
                sendPublicLanguages: false
            };
        case publicConstants.GET_PUBLIC_LANGUAGES_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPublicLanguages: false
            };
        case publicConstants.GET_PUBLIC_LOCALIZATION_REQUEST:
            return {
                ...state,
                sendPublicLocalization: true,
                send: true
            };
        case publicConstants.GET_PUBLIC_LOCALIZATION_SUCCESS:
            return {
                ...state,
                publicLocalization: action.payload,
                send: false,
                sendPublicLocalization: false
            };
        case publicConstants.GET_PUBLIC_LOCALIZATION_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPublicLocalization: false
            };
        case publicConstants.GET_PUBLIC_SERVICE_AREAS_REQUEST:
            return {
                ...state,
                send: true,
                sendPublicServiceAreasGET: true
            };
        case publicConstants.GET_PUBLIC_SERVICE_AREAS_SUCCESS:
            return {
                ...state,
                send: false,
                sendPublicServiceAreasGET: false,
                publicServiceAreas: action.payload
            };
        case publicConstants.GET_PUBLIC_SERVICE_AREAS_ERROR:
            return {
                ...state,
                send: false,
                sendPublicServiceAreasGET: false,
                errors: action.error
            };
        case publicConstants.GET_PUBLIC_CONTENT_REQUEST:
            return {
                ...state,
                send: true,
                sendPublicContentGET: true
            };
        case publicConstants.GET_PUBLIC_CONTENT_SUCCESS:
            return {
                ...state,
                publicContent: action.payload,
                send: false,
                sendPublicContentGET: false
            };
        case publicConstants.GET_PUBLIC_CONTENT_ERROR:
            return {
                ...state,
                send: false,
                sendPublicContentGET: false,
                errors: action.error
            };
        case publicConstants.GET_PUBLIC_CONFIGURATIONS_REQUEST:
            return {
                ...state,
                send: true
            };
        case publicConstants.GET_PUBLIC_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                publicConfigurations: action.payload,
                send: false
            };
        case publicConstants.GET_PUBLIC_CONFIGURATIONS_ERROR:
            return {
                ...state,
                send: false,
                errors: action.error
            };
        default:
            return state;
    }
};

import axios from 'axios';

import { getCountry, getLanguage, getToken } from '../../utilities';
import { env } from '../../configs';

const api = env.REACT_APP_API_URL;
const odata = env.REACT_APP_ODATA_URL;

export const methods = {
    get: 'GET',
    patch: 'PATCH',
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE'
};

export const responseTypes = {
    arrayBuffer: 'arraybuffer'
};

export const requestApi = axios.create({
    baseURL: api,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    transformRequest: [
        function (data) {
            return JSON.stringify(data);
        }
    ]
});

export const requestApiFormData = axios.create({
    baseURL: api,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const requestOdata = axios.create({
    baseURL: odata,
    headers: {
        'Content-Type': 'application/json'
    },
    transformRequest: [
        function (data) {
            return JSON.stringify(data);
        }
    ]
});

requestApi.defaults.headers.common.Authorization = getToken();
requestApi.defaults.headers.common.CountryCode = getCountry();
requestApi.defaults.headers.common.LangCode = getLanguage();

requestApiFormData.defaults.headers.common.Authorization = getToken();
requestApiFormData.defaults.headers.common.CountryCode = getCountry();
requestApiFormData.defaults.headers.common.LangCode = getLanguage();

requestOdata.defaults.headers.common.Authorization = getToken();
requestOdata.defaults.headers.common.CountryCode = getCountry();
requestOdata.defaults.headers.common.LangCode = getLanguage();

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Table, Popconfirm, Button } from 'antd';
import ScrollContainer from 'react-indiana-drag-scroll';
import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';

import { classificator, classificatorTranslatedArray, formatDate, i18, i18cl, i18clGroups, oDataQuery, routerPaths, translate } from '../../../utilities';
import { history, odataActions, JobBiddingStageConstants, jobActions, JobVatTypeConstants } from '../../../api';
import { CustomModal, FilterField, filterFieldTypes, Translate } from '../../../components';
import { fieldNames } from '../fieldNames';
import { isJobAnOrder } from '../../jobsCreate/utilities/jobUtilities';
import { AddCompetitorButton } from './components';
import { AddCompetitorModal } from './components/addCompetitorModal';

const defaultFilterWithPagination = {
    filters: [],
    pagination: {
        current: 1,
        pageSize: 10
    }
};

const defaultFilterForOrder = {
    orders: [
        {
            name: 'Stage',
            order: 'asc'
        },
        {
            name: 'Price',
            order: 'asc'
        }
    ],
    ...defaultFilterWithPagination
};

const mandatoryWorkOrder = {
    name: 'IsWinner',
    order: 'desc'
};

const defaultFilterForWork = {
    orders: [
        mandatoryWorkOrder,
        {
            name: 'Stage',
            order: 'asc'
        },
        {
            name: 'Price',
            order: 'asc'
        }
    ],
    ...defaultFilterWithPagination
};

class JobBiddingsGrid extends React.Component {
    constructor(props) {
        super(props);

        const defaultFilter = this.getDefaultFilter(props.job.jobStatus);

        this.state = {
            ...(this.props.history.location.state?.filter || defaultFilter),
            defaultFilter: defaultFilter
        };

        this.setFilter = this.setFilter.bind(this);
        this.setOrderAndPagination = this.setOrderAndPagination.bind(this);
        this.click = this.click.bind(this);
        this.canShowApproveButton = this.canShowApproveButton.bind(this);
        this.canApproveWinner = this.canApproveWinner.bind(this);
        this.onApproveConfirm = this.onApproveConfirm.bind(this);
        this.shouldShowBiddingInformation = this.shouldShowBiddingInformation.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }

    getDefaultFilter(jobStatusCode) {
        if (isJobAnOrder(jobStatusCode)) {
            return defaultFilterForOrder;
        } else {
            return defaultFilterForWork;
        }
    }

    fetch = () => {
        this.props.dispatch(odataActions.getJobBiddingsGrid(this.props.match.params.jobId, oDataQuery(this.state)));
    };

    setFilter(filter, resetPagination) {
        this.setState(
            prevState => ({
                filters: [
                    ...prevState.filters.filter(e => e.name !== filter.name),
                    ...(filter.value !== null ? [filter] : [])
                ],
                ...(resetPagination && {
                    pagination: this.state.defaultFilter.pagination
                })
            }),
            () => {
                this.fetch();
            }
        );
    }

    setOrderAndPagination(pagination, columns, order) {
        const orders = [];

        if (Array.isArray(order)) {
            order.forEach(item => {
                orders.unshift({
                    name: item.columnKey,
                    order: item.order === 'ascend' ? 'asc' : 'desc'
                });
            });
        } else if (order.order) {
            orders.unshift({
                name: order.columnKey,
                order: order.order === 'ascend' ? 'asc' : 'desc'
            });
        }

        if (!isJobAnOrder(this.props.job.jobStatus)) {
            orders.unshift(mandatoryWorkOrder);
        }

        this.setState(
            {
                orders: orders,
                pagination: {
                    current: pagination.current,
                    pageSize: pagination.pageSize
                }
            },
            () => {
                this.fetch();
            }
        );
    }

    click(profileId) {
        history.push(routerPaths.partners.index + '/' + profileId + '/profile', {
            filter: this.state,
            prevRoute: this.props.history.location.pathname,
            jobPrevRoute: this.props.history.location?.state?.prevRoute,
            jobPrevState: this.props.history.location?.state
        });
    }

    shouldShowBiddingInformation(item) {
        const isJobTypeEmergency = this.props.job[fieldNames.jobType] === 'EMERGENCY';
        const hasJobAdExpired = this.props.job[fieldNames.hasJobAdExpired];
        const isStageNotAnswered = item.Stage === JobBiddingStageConstants.NOT_ANSWERED;
        const showBiddingPrices = this.props.job[fieldNames.showPriceDuringBidding];

        if (hasJobAdExpired) {
            return true;
        }

        if (isJobTypeEmergency && !isStageNotAnswered) {
            return true;
        }

        if (showBiddingPrices && !isStageNotAnswered) {
            return true;
        }

        return false;
    }

    canShowApproveButton(item) {
        const { job } = this.props;
        return (
            this.shouldShowBiddingInformation(item) &&
            job.bidConfirmationNeeded &&
            item.Stage === JobBiddingStageConstants.MADE_BID
        );
    }

    canApproveWinner() {
        const { job } = this.props;

        return !job.isJobApproved && job.allowIntegratorActions;
    }

    onApproveConfirm(partnerId) {
        this.props.onApproveWinner(partnerId).then(() => {
            this.props.reloadBiddingListData(this.props.match.params.jobId, oDataQuery(this.state));
        });
    }

    onViewJobScopeClick(partnerId) {
        this.props.onViewJobScopeFile(partnerId).then(response => {
            const fileUrl = response.payload.data.fileLink;
            window.open(fileUrl, '_blank', 'noopener,noreferrer');
        });
    }

    onOpenCompetitorModal = ({
        isEdit,
        orderBidId,
        initialPartnerId,
        initialClientPrice,
        initialPartnerReward,
        initialAdminFee,
        initialMaterialsPrice,
        initialVatName,
        initialMaterialsVatName,
        initialMaterialsVatType,
        initialVatType
    } = {}) => {
        const vatTypes = classificatorTranslatedArray(i18cl.JOB_VAT_TYPES.replace(/\.$/, ''));

        const defaultVatType = vatTypes.find(vatType => {
            return vatType.value === JobVatTypeConstants.STANDARD;
        }).value;

        CustomModal.open({
            content: (
                <AddCompetitorModal
                    jobId={this.props.match.params.jobId}
                    serviceId={this.props.serviceId}
                    orderBidId={orderBidId}
                    isEdit={isEdit}
                    initialPartnerId={initialPartnerId}
                    initialClientPrice={initialClientPrice}
                    initialPartnerReward={initialPartnerReward}
                    initialAdminFee={initialAdminFee}
                    initialMaterialsPrice={initialMaterialsPrice}
                    initialVatName={initialVatName || this.props.job.vatName}
                    initialMaterialsVatName={initialMaterialsVatName || this.props.job.materialsVatName}
                    initialMaterialsVatType={initialMaterialsVatType || this.props.job.materialsVatType}
                    initialVatType={initialVatType || defaultVatType}
                    availableVats={this.props.job?.availableVats?.map(vat => ({
                        value: vat.name,
                        label: vat.name,
                        percentage: vat.percentage,
                    })) ?? []}
                    availableMaterialVats={
                        this.props.job?.availableMaterialVats?.map(vat => ({
                            value: vat.name,
                            label: vat.name,
                            percentage: vat.percentage,
                        })) ?? []
                    }
                    onReload={this.fetch}
                />
            ),
            hideOkButton: true,
            closable: true,
            width: 1000,
            title: translate(i18.Job.Labels.AddCompetitorModalTitle),
            style: {
                minWidth: 1000
            }
        });
    };

    deleteCompetitorOffer = async orderBidId => {
        await this.props.dispatch(jobActions.deleteCompetitorOffer(this.props.match.params.jobId, orderBidId));
        this.fetch();
    };

    render() {
        const actions = {
            render: (item, index) => {
                const canEditoCompetitorOffer =
                    this.props.isEditable &&
                    !!this.props.serviceId &&
                    !!this.props.match.params.jobId &&
                    item.Stage !== JobBiddingStageConstants.NOT_ANSWERED;
                const canRemoveCompetitorOffer =
                    this.props.isEditable &&
                    !!this.props.match.params.jobId &&
                    item.Stage !== JobBiddingStageConstants.NOT_ANSWERED;

                return (
                    <div key={index} className="text-right text-nowrap">
                        {item.IsWinner && <Translate value={i18.Job.Labels.BiddingWinner} />}
                        {this.canApproveWinner() && this.canShowApproveButton(item) && (
                            <Popconfirm
                                zIndex={999}
                                placement="bottomLeft"
                                title={<Translate value={i18.Job.Confirms.JobApprove} />}
                                onConfirm={() => {
                                    this.onApproveConfirm(item.PartnerId);
                                }}
                                okText={<Translate value={i18.Job.Buttons.ConfirmJobApprove} />}
                                cancelText={<Translate value={i18.Job.Buttons.CancelJobApprove} />}
                            >
                                <Button className="rounded border-0 job-confirm-button ml-3">
                                    <Translate value={i18.Job.Buttons.ApproveJob} />
                                </Button>
                            </Popconfirm>
                        )}
                        {item.JobScopeFileName && (
                            <Button
                                className="rounded border-0 job-confirm-button ml-3"
                                onClick={() => {
                                    this.onViewJobScopeClick(item.PartnerId);
                                }}
                            >
                                <Translate value={i18.Job.Buttons.ViewBidJobScope} />
                            </Button>
                        )}
                        {canEditoCompetitorOffer && (
                            <Button
                                className="rounded border-0 job-edit-button ml-3"
                                onClick={() => {
                                    this.onOpenCompetitorModal({
                                        isEdit: true,
                                        orderBidId: item.OrderBidId,
                                        initialPartnerId: item.PartnerId,
                                        initialClientPrice: item.Price,
                                        initialPartnerReward: item.PartnerReward,
                                        initialAdminFee: item.AdministrationFee,
                                        initialMaterialsPrice: item.PlannedMaterialExpenses,
                                        initialVatName: item.VatName,
                                        initialMaterialsVatName: item.PlannedMaterialExpensesVatName,
                                        initialMaterialsVatType: item.PlannedMaterialExpensesVatType,
                                        initialVatType: item.VatType
                                    });
                                }}
                            >
                                <EditOutlined />
                            </Button>
                        )}
                        {canRemoveCompetitorOffer && (
                            <Popconfirm
                                zIndex={999}
                                placement="bottomLeft"
                                title={<Translate value={i18.Job.Labels.RemoveCompetitorModalTitle} />}
                                onConfirm={() => {
                                    this.deleteCompetitorOffer(item.OrderBidId);
                                }}
                                okText={<Translate value={i18.Job.Buttons.ConfirmRemoveCompetitor} />}
                                cancelText={<Translate value={i18.Job.Buttons.CancelRemoveCompetitor} />}
                            >
                                <Button className="rounded border-0 job-remove-button ml-3">
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        )}
                        <Button
                            className="rounded border-0 job-accept-button ml-3"
                            onClick={() => handleRowClick(item)}
                        >
                            <EyeFilled />
                        </Button>
                    </div>
                );
            }
        };

        const handleRowClick = record => {
            const partnerProfilePath = '/partners/' + record.PartnerId + '/profile';
            history.push(partnerProfilePath);
        };

        const columns = [
            {
                dataIndex: 'PartnerFullName',
                key: 'PartnerFullName',
                title: <Translate value={i18.Job.Labels.BiddingPartnerFullName} />,
                ...FilterField({
                    name: 'PartnerFullName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'PartnerFullName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'PartnerFullName')
                })
            },
            {
                dataIndex: 'CompanyName',
                key: 'CompanyName',
                title: <Translate value={i18.Job.Labels.BiddingPartnerCompanyName} />,
                ...FilterField({
                    name: 'CompanyName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'CompanyName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'CompanyName')
                })
            },
            {
                dataIndex: 'PhoneNumber',
                key: 'PhoneNumber',
                title: <Translate value={i18.Job.Labels.PhoneNumber} />,
                ...FilterField({
                    name: 'PhoneNumber',
                    sortFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'PhoneNumber'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'PhoneNumber')
                })
            },
            {
                key: 'Price',
                title: <Translate value={i18.Job.Labels.ClientPaidPrice} />,
                render: item => this.shouldShowBiddingInformation(item) && item.Price,
                ...FilterField({
                    name: 'Price',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'Price'),
                    type: filterFieldTypes.number,
                    data: this.state.filters.find(e => e.name === 'Price')
                })
            },
            {
                dataIndex: 'VatName',
                key: 'VatName',
                title: <Translate value={i18.Job.Labels.ServiceVatPercentages} />,
                ...FilterField({
                    name: 'VatName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'VatName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'VatName')
                })
            },
            {
                key: 'Stage',
                title: <Translate value={i18.Job.Labels.BiddingPartnerStage} />,
                render: item => classificator(`${i18clGroups.BIDDING_STAGES}.${item.Stage}`),
                ...FilterField({
                    name: 'Stage',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'Stage'),
                    type: filterFieldTypes.disabled,
                    data: this.state.filters.find(e => e.name === 'Stage')
                })
            },
            {
                dataIndex: 'JobScopeDescription',
                key: 'JobScopeDescription',
                title: <Translate value={i18.Job.Labels.JobScopeDescription} />,
                ...FilterField({
                    name: 'JobScopeDescription',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'JobScopeDescription'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'JobScopeDescription')
                })
            },
            {
                key: 'PlannedMaterialExpenses',
                title: <Translate value={i18.Job.Labels.PlannedMaterialExpenses} />,
                render: item => item?.PlannedMaterialExpenses || '',
                ...FilterField({
                    name: 'PlannedMaterialExpenses',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'PlannedMaterialExpenses'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'PlannedMaterialExpenses')
                })
            },
            {
                dataIndex: 'PlannedMaterialExpensesVatName',
                key: 'PlannedMaterialExpensesVatName',
                title: <Translate value={i18.Job.Labels.MaterialsVatPercentages} />,
                ...FilterField({
                    name: 'PlannedMaterialExpensesVatName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'PlannedMaterialExpensesVatName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'PlannedMaterialExpensesVatName')
                })
            },
            {
                key: 'PlannedStartDate',
                title: <Translate value={i18.Job.Labels.PlannedStartDate} />,
                render: item => formatDate(item.PlannedStartDate, 'dateTime'),
                ...FilterField({
                    name: 'PlannedStartDate',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'PlannedStartDate'),
                    type: filterFieldTypes.date,
                    data: this.state.filters.find(e => e.name === 'PlannedStartDate')
                })
            },
            {
                key: 'BidDateTime',
                title: <Translate value={i18.Job.Labels.BiddingPartnerBiddingDate} />,
                render: item => formatDate(item.BidDateTime, 'dateTime'),
                ...FilterField({
                    name: 'BidDateTime',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'BidDateTime'),
                    type: filterFieldTypes.date,
                    data: this.state.filters.find(e => e.name === 'BidDateTime')
                })
            },
            {
                dataIndex: 'RejectReason',
                key: 'RejectReason',
                title: <Translate value={i18.Job.Labels.RejectReason} />,
                ...FilterField({
                    name: 'RejectReason',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'RejectReason'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'RejectReason')
                })
            },
            actions
        ];

        const canCreateCompetitorOffer =
            this.props.isEditable && !!this.props.serviceId && !!this.props.match.params.jobId;

        return (
            <div className="content_block">
                <ScrollContainer
                    style={{
                        overflowX: 'auto'
                    }}
                >
                    <Table
                        rowKey="PartnerId"
                        style={{ minWidth: 1000 }}
                        onChange={(pagination, columns, order) =>
                            this.setOrderAndPagination(pagination, columns, order)
                        }
                        columns={columns}
                        rowClassName="cursor_pointer"
                        dataSource={this.props.odataJobBiddingsGrid.value || []}
                        loading={this.props.isLoading}
                        pagination={{
                            total: this.props.odataJobBiddingsGrid['@odata.count'],
                            pageSize: this.state.pagination.pageSize,
                            current: this.state.pagination.current,
                            showSizeChanger: this.props.odataJobBiddingsGrid['@odata.count'] > 10
                        }}
                    />
                </ScrollContainer>
                {canCreateCompetitorOffer && (
                    <AddCompetitorButton
                        serviceId={this.props.serviceId}
                        onOpenModal={() => {
                            this.onOpenCompetitorModal();
                        }}
                    />
                )}
            </div>
        );
    }
}

JobBiddingsGrid.propTypes = {
    dispatch: PropTypes.func.isRequired,
    job: PropTypes.object,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    odataJobBiddingsGrid: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    onApproveWinner: PropTypes.func,
    reloadBiddingListData: PropTypes.func,
    onViewJobScopeFile: PropTypes.func,
    serviceId: PropTypes.number,
    isEditable: PropTypes.bool
};

function mapStateToProps(state) {
    const { sendCompetitorOfferDELETE } = state.jobReducers;
    const { odataJobBiddingsGrid, sendOdataJobBiddingsGrid } = state.odataReducers;

    const { job } = state.jobReducers;

    return {
        job: job?.value || {},
        odataJobBiddingsGrid,
        isLoading: sendOdataJobBiddingsGrid || sendCompetitorOfferDELETE
    };
}

const connectedJobBiddingsGrid = withRouter(connect(mapStateToProps)(JobBiddingsGrid));
export { connectedJobBiddingsGrid as JobBiddingsGrid };

/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { i18, oDataQuery, required } from '../../../../utilities';
import { FormField, Translate, filterFieldTypes, formFieldType } from '../../../../components';
import { Table } from 'antd';
import { odataActions, odataServices, showErrorNotification } from '../../../../api';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

function OfferForm({ offers, jobId, serviceId }) {
    const dispatch = useDispatch();
    const [partnerNames, setPartnerNames] = useState([]);
    const form = useForm();

    const fetchPartnerNames = useCallback(
        async (searchValue = '') => {
            try {
                const result = await odataServices.getJobPartnersGridForService(
                    serviceId,
                    oDataQuery({
                        filters: [
                            {
                                name: 'SearchAggregate',
                                type: filterFieldTypes.text,
                                value: searchValue
                            }
                        ],
                        orders: [],
                        pagination: {
                            pageSize: 100,
                            current: 1
                        },
                        select: []
                    })
                );

                if (result.value) {
                    setPartnerNames(
                        result.value
                            .map(item => ({
                                label: item.FirstName + ' ' + item.LastName,
                                value: item.PartnerId
                            }))
                            .filter(partner => {
                                const partnerId = partner.value;
                                const formValues = form.getState().values;
                                const partnerIds = Object.values(formValues || {}).map(value => value.partnerId);
                                return !partnerIds.includes(partnerId);
                            })
                    );
                }
            } catch (error) {
                showErrorNotification({ error });
            }
        },
        [form, serviceId]
    );


    useEffect(() => {
        fetchPartnerNames('');
    }, [fetchPartnerNames]);

    const debouncedFetchPartnerNames = debounce(fetchPartnerNames, 300);

    useEffect(() => {
        dispatch(odataActions.getJobBiddingsGrid(jobId, ''));
    }, [dispatch, jobId]);

    useEffect(() => {
        if (offers && offers.length > 0) {
            const values = offers.reduce((prev, curr, idx) => ({ ...prev, [`k${idx}`]: curr }), {});
            form.reset(values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offers]);

    useEffect(() => {
        if (partnerNames.length === 0) {
            return;
        }

        const getPartnerNameById = (partnerId, currentPartnerFullName) => {
            const partner = partnerNames.find(p => p.value === partnerId);
            return partner ? partner.label : currentPartnerFullName;
        };

        const unsubscribe = form.subscribe(
            ({ values }) => {
                Object.keys(values).forEach(key => {
                    const { partnerId, partnerFullName: currentPartnerFullName } = values[key];
                    const newPartnerFullName = getPartnerNameById(partnerId, currentPartnerFullName);

                    if (newPartnerFullName !== currentPartnerFullName) {
                        form.change(`${key}.partnerFullName`, newPartnerFullName);
                    }
                });
            },
            { values: true }
        );

        return () => unsubscribe();
    }, [form, partnerNames]);

    return (
        <Table
            dataSource={offers}
            rowKey={record => record.partnerId}
            columns={[
                {
                    title: <Translate value={i18.Job.Labels.OffersPartner} />,
                    dataIndex: 'partnerFullName',
                    render: (value, record, index) => {
                        return (
                            <FormField
                                name={`k${index}.partnerId`}
                                validate={required}
                                component={formFieldType.select}
                                onSearch={debouncedFetchPartnerNames}
                                options={partnerNames}
                            />
                        );
                    }
                },
                {
                    title: <Translate value={i18.Job.Labels.OffersClientPrice} />,
                    dataIndex: 'clientPrice',
                    render: (value, record, index) => {
                        return (
                            <FormField
                                name={`k${index}.clientPrice`}
                                validate={required}
                                component={formFieldType.number}
                            />
                        );
                    }
                },
                {
                    title: <Translate value={i18.Job.Labels.OffersAdminFee} />,
                    dataIndex: 'adminFee',
                    render: (value, record, index) => {
                        return (
                            <FormField
                                name={`k${index}.adminFee`}
                                validate={required}
                                component={formFieldType.number}
                            />
                        );
                    }
                },
                {
                    title: <Translate value={i18.Job.Labels.OffersPartnerReward} />,
                    dataIndex: 'partnerReward',
                    render: (value, record, index) => {
                        return (
                            <FormField
                                name={`k${index}.partnerReward`}
                                validate={required}
                                component={formFieldType.number}
                            />
                        );
                    }
                },
                {
                    title: <Translate value={i18.Job.Labels.OffersMaterialPrice} />,
                    dataIndex: 'materialsPrice',
                    render: (value, record, index) => {
                        return <FormField name={`k${index}.materialsPrice`} component={formFieldType.number} />;
                    }
                }
            ]}
        />
    );
}

export default OfferForm;
